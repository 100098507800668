// @warning
// Only use NEXT_PUBLIC variables here as the values will be exposed in the bundle
export const common = {
  fallbackImage:
    process.env['NEXT_PUBLIC_FALLBACK_ARTICLE_IMAGE'] ||
    // @todo replace this with a more generic fallback image
    'https://st.motortrend.com/uploads/sites/11/2020/10/2010-Jaguar-C-X75-concept-front-three-quarter.jpg',
  sonic: {
    appName: 'MTNEXT',
    appPlatform: 'WEB',
    realm: 'motortrend',
  },
};
